import UserContextProvider from "./shared/context/userContext";
import Routes from "./shared/routes/routes";
import { GlobalOverrideCss } from "./App.styles";
import ToastWrapper from "./components/toast/toast";
import LoadingModalWrapper from "./components/loadingModal/loadingModalWrapper";
import "react-toastify/dist/ReactToastify.css";
import GlobalMessageProvider from "./shared/context/globalMessage.context";
import ThemeProvider from "./shared/context/theme.context";
import { MantineProvider } from '@mantine/core';

const App = () => {
  return (
    <MantineProvider>
      <ThemeProvider>
        <GlobalMessageProvider>
          <UserContextProvider>
            <GlobalOverrideCss />
            <LoadingModalWrapper>
              <Routes />
              <ToastWrapper />
            </LoadingModalWrapper>
          </UserContextProvider>
        </GlobalMessageProvider>
      </ThemeProvider>
    </MantineProvider>
  );
};

export default App;
