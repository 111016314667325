import { useMutation } from '@apollo/client';
import { ChangeEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR } from '../../components/accountsSearch/accountsSearch.service';
import AccountTypeForm from '../../components/forms/accountType/accountType';
import ConfirmationForm from '../../components/forms/confirmation/confirmation';
import PersonalInformationForm from '../../components/forms/personalInformation/personalInformation';
import { LoadingModalContext } from '../../components/loadingModal/loadingModalContext';
import StepperContainer from '../../components/stepper/stepperContainer';
import { triggerAlert } from '../../components/toast/toast';
import { uploadFileToBucket } from '../../components/uploadFile/uploadFile.service';
import { UserContext } from '../../shared/context/userContext';
import { FormTypes } from '../../shared/interfaces/formTypes.enum';
import {
  IAdvisorPreFillFields,
  IStepCanAdvance,
} from '../../shared/interfaces/iform';
import { getPath, RouteName } from '../../shared/routes/routeList';
import { formatFormInput } from '../../shared/utils/formUtils';
import {
  ERROR,
  ERROR_ONBOARDING_MSG,
  SUCCESS,
  SUCCESS_ONBOARDING_MSG,
} from '../../shared/utils/textConstants';
import { SUBMIT_ADVISOR_PREFILL } from './advisorPreFillWorkflow.service';
import * as Sentry from "@sentry/react";

const advisorPreFillFormDefault: IAdvisorPreFillFields = {
  accountType: '',
  firstName: '',
  lastName: '',
  ssn: '',
  dateOfBirth: '',
  phoneNumber: '',
  email: '',
  address: '',
  firstName2: '',
  lastName2: '',
  ssn2: '',
  dateOfBirth2: '',
  phoneNumber2: '',
  email2: '',
  address2: '',
  accountAccess: '',
  entityName: '',
  taxId: '',
  accountTitle: '',
  trustDocumentURL: '',
  entityEmail: '',
};

const stepCanAdvanceDefault: IStepCanAdvance = {
  typeOfAccount: false,
  personalInformation: false,
  confirmation: true,
};

const AdvisorPreFillWorkflow = () => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [formFields, setFormFields] = useState<IAdvisorPreFillFields>(
    advisorPreFillFormDefault
  );
  const [stepCanAdvance, setStepCanAdvance] = useState<IStepCanAdvance>(
    stepCanAdvanceDefault
  );
  const [submitAdvisorPreFill] = useMutation(SUBMIT_ADVISOR_PREFILL, {
    refetchQueries: [
      GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR,
      'AccountsByHildaStatus',
    ],
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const { setOpenModal } = useContext(LoadingModalContext);

  const handleSetFileToUpload = (file: File) => {
    setFileToUpload(file);
  };

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setFormFields((prevForm) => {
      return { ...prevForm, [label]: formatFormInput(type, value) };
    });
  };

  const handleSetStepCanAdvance = (id: string, flag: boolean) => {
    setStepCanAdvance((prevState) => {
      return { ...prevState, [id]: flag };
    });
  };

  const clearFormFields = () => {
    setFormFields(advisorPreFillFormDefault);
  };

  const submit = async () => {
    try {
      setOpenModal(true);
      const bucketRes =
        fileToUpload && (await uploadFileToBucket(fileToUpload, 'trust'));
      await submitAdvisorPreFill({
        variables: {
          onboardingForm: {
            ...formFields,
            trustDocumentURL: bucketRes?.uploadUrl,
          },
        },
      });
      setOpenModal(false);
      triggerAlert({
        type: 'success',
        title: SUCCESS,
        msg: SUCCESS_ONBOARDING_MSG('create'),
      });
      setSubmitSuccess(true);
      setTimeout(
        () =>
          history.push(
            getPath(RouteName.Accounts, {
              advisorFirmId: userData.user.advisorFirmId,
            })
          ),
        500
      );
    } catch (e) {
      Sentry.captureException(e)
      console.error(e);
      setOpenModal(false);
      triggerAlert({
        type: 'error',
        title: ERROR,
        msg: ERROR_ONBOARDING_MSG('create'),
      });
    }
  };

  return (
    <StepperContainer
      steps={[
        {
          label: 'Type of account',
          form: (
            <AccountTypeForm
              id="typeOfAccount"
              formFields={formFields}
              handleSetStepCanAdvance={handleSetStepCanAdvance}
              handleValueChange={handleValueChange}
            />
          ),
          buttonBack: false,
          nextValidated: stepCanAdvance.typeOfAccount,
        },
        {
          label: 'Personal information',
          form: (
            <PersonalInformationForm
              id="personalInformation"
              formFields={formFields}
              type={formFields.accountType}
              handleSetStepCanAdvance={handleSetStepCanAdvance}
              handleValueChange={handleValueChange}
              handleSetFileToUpload={handleSetFileToUpload}
            />
          ),
          buttonBack: false,
          nextValidated: stepCanAdvance.personalInformation,
        },
        {
          label: 'Confirmation',
          form: (
            <ConfirmationForm
              displayTitle={false}
              hildaAccountInfo={{ ...formFields, advisor: userData.user }}
            />
          ),
          buttonBack: true,
          nextValidated: stepCanAdvance.confirmation,
        },
      ]}
      startOver={true}
      clearFormFields={clearFormFields}
      handleSubmit={submit}
      submitSuccess={submitSuccess}
    />
  );
};

export default AdvisorPreFillWorkflow;
