export enum FormTypes {
  Text = 'text',
  SSN = 'ssn',
  Date = 'date',
  Phone = 'phone',
  Email = 'email',
  EntityEmail = 'entityEmail',
  Address = 'address',
  TaxId = 'taxId',
  AccountNumber = 'accountNumber',
  AccountTitle = 'accountTitle',
  Alphanumeric = 'alphanumeric',
  PrimaryColor='primaryColor',
  SecondaryColor='secondaryColor'
}
