import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors, Typography } from '../../shared/theme/styles.enum';
import { drawerWidth } from '../sideNav/sideNav.styles';

export const useStyles = makeStyles((theme: Theme) => {
  const secondary: any = theme.palette.secondary;
  return createStyles({
    root: {
      display: 'flex',
      backgroundColor: Colors.BorderAndBG,
      minHeight: '100vh',
      justifyContent: 'center',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: 74,
      backgroundColor: secondary.main,
    },
    navbar: {
      width: '90%',
      maxWidth: '90%',
      padding: 0,
      '@media (max-width:950px)': {
        // eslint-disable-line no-useless-computed-key
        maxWidth: '100% !important',
      },
    },
    menuButton: {
      marginRight: theme.spacing(1),
      display: 'none',
    },
    ifiLogo: {
      fontSize: '56px',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 74,
      backgroundColor: Colors.White,
      width: '90%',
      '@media (max-width:950px)': {
        // eslint-disable-line no-useless-computed-key
        width: '100%',
      },
    },
    menuItem: {
      fontSize: Typography.H4,
      fontFamily: Typography.FontFamily2,
      fontWeight: 'bold',
      padding: '.1rem',
    },
    menu: {
      color: `${theme.palette.secondary.contrastText} !important`,
      opacity: '0.6',
      textDecoration: 'none !important',
    },
    menuActive: {
      color: `${theme.palette.secondary.contrastText} !important`,
      opacity: '1',
      borderBottom: `4px solid ${theme.palette.primary.main} !important`,
    },
    buttonLink: {
      color: Colors.BorderAndBG,
      textDecoration: 'none !important',
    },
    container: {
      marginTop: '80px !important',
      alignItems: 'center !important',
    },
    box: {
      width: '90%',
      display: 'grid',
      gridTemplateColumns: '352px 1fr',
      alignItems: 'center',
      padding: 0,
      margin: 0,
    },
    logoContainer: {
      display: 'flex',
      alignContent: 'flex-start',
      width: drawerWidth,
      margin: 0,
      padding: 0,
    },
    optionsContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      width: '100%',
    },
    menuOptionsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'center',
      width: '100%',
    },
    selectMenuContainer: {
      display: 'flex',
      width: '100%',
      alignContent: 'center',
      justifyContent: 'flex-end',
    },
  });
});
