import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../shared/context/userContext";
import {
  AccountAccess,
  AccountAccessVal,
} from "../../../shared/interfaces/accountAccess.enum";
import { IBankAccount } from "../../../shared/interfaces/bankAccount.interface";
import { IHilda } from "../../../shared/interfaces/ihilda.interface";
import { IProductSummary } from "../../../shared/interfaces/iproductSummary.interface";
import { Roles } from "../../../shared/interfaces/roles.enum";
import { TransferType } from "../../../shared/interfaces/transferType.enum";
import { getPath, RouteName } from "../../../shared/routes/routeList";
import { formatCurrencyNumber } from "../../../shared/utils/gridUtils";
import { clear } from "../../../shared/utils/pathConstants";
import {
  ERROR,
  SUCCESS,
  TRANSFER_SUBTITLE,
} from "../../../shared/utils/textConstants";
import DropDown, { IDropDownOption } from "../../dropDown/dropDown";
import Link from "../../link/link";
import Loading from "../../loading/loading";
import { triggerAlert } from "../../toast/toast";
import WireModal from "../../wireModal/wireModal";
import { NumberFormatCustom } from "./components/number-format";
import {
  GET_BANK_ACCOUNTS_AND_ALLOCATIONS,
  CREATE_TRANSACTION,
  GET_BANK_ACCOUNT_BY_BANK_ALLOCATION_ID,
} from "./transfer.service";
import { useStyles } from "./transfer.styles";
import * as Sentry from "@sentry/react";

interface ITransferProps {
  handleCloseDrawer: () => void;
}

const Transfer = (props: ITransferProps) => {
  const { handleCloseDrawer } = props;
  const { userData } = useContext(UserContext);
  const params: { hildaAccountId: string } = useParams();
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { data, loading, error, refetch } = useQuery(
    GET_BANK_ACCOUNTS_AND_ALLOCATIONS,
    {
      variables: {
        hildaId: params.hildaAccountId,
        whereHilda: { id: params.hildaAccountId },
        hildaWhereHilda: { id: params.hildaAccountId },
      },
    }
  );

  const [getBankAccountByBankAllocationId] = useLazyQuery(
    GET_BANK_ACCOUNT_BY_BANK_ALLOCATION_ID
  );

  const [bankAccounts, setBankAccounts] = useState<IDropDownOption[]>([]);
  const [bankAllocations, setBankAllocations] = useState<IDropDownOption[]>([]);
  const [bankAccountId, setBankAccountId] = useState<string>("");
  const [bankAllocationId, setBankAllocationId] = useState<string>("");
  const [hilda, setHilda] = useState<IHilda>();
  const [source, setSource] = useState<any>("");
  const [destination, setDestination] = useState<any>("");
  const [amount, setAmount] = useState<string>("");
  const [openWireInstructions, setOpenWireInstructions] = useState(false);
  const [bankAccountInfo, setBankAccountInfo] = useState<any>([]);
  const [createTransaction] = useMutation(CREATE_TRANSACTION);
  const classes = useStyles();

  const canLinkBankAccount = userData.user.role.name === Roles.HildaUser;

  useEffect(() => {
    refetch();
  }, [refetch, props]);

  useEffect(() => {
    if (data && !loading && !error) {
      setHilda(data.hilda);
      const bankAccounts: IBankAccount[] = data.bankAccounts;
      const productSummaries: IProductSummary[] = data.productSummaries;
      const bankAllocations = productSummaries.flatMap(
        (ba: any) => ba.bankAllocations
      );
      const bankAccountProducts = productSummaries.flatMap(
        (ba: any) => ba.bankAccounts
      );
      const allBankAccounts = bankAccounts
        .filter(
          (ba) =>
            ba.plaidAccountName ||
            ba.beneficiaryAccountName === AccountAccessVal.Wire
        )
        .concat(bankAccountProducts);
      setBankAccounts(mapItems(allBankAccounts));
      const allBankAllocations = bankAllocations.concat(
        bankAccounts.filter(
          (ba) =>
            ba.plaidAccountName ||
            ba.beneficiaryAccountName !== AccountAccessVal.Wire
        )
      );
      const sourceBankAccountItem = allBankAccounts.find(
        (ba) => ba.id === bankAccountId
      );
      const destinationBankAllocationItem = allBankAllocations.find(
        (ba) => ba.id === bankAllocationId
      );
      if (
        sourceBankAccountItem?.availableBalance &&
        sourceBankAccountItem.availableBalance > 0
      ) {
        setBankAllocations(
          mapItems(
            allBankAllocations.filter(
              (ba: any) => ba.bankAccountId !== bankAccountId
            ),
            TransferType.Destination
          )
        );
      } else {
        setBankAllocations(
          mapItems(
            allBankAllocations.filter((ba: any) => !ba.accountRef),
            TransferType.Destination
          )
        );
      }
      setSource(sourceBankAccountItem);
      setDestination(destinationBankAllocationItem);
    }
    // eslint-disable-next-line
  }, [data, loading, error, bankAccountId, bankAllocationId]);

  const linkBankAccountPath = getPath(RouteName.PlaidView, {
    advisorFirmId: userData.user.advisorFirmId,
  });

  const handleSubmit = async () => {
    try {
      const accountAccess: any = await createTransaction({
        variables: {
          createTransactionInput: {
            amount: amount?.replace(/,/g, ""),
            bankAccountId,
            bankAllocationId,
            hildaId: params.hildaAccountId,
          },
        },
      });
      if (
        accountAccess.data.createTransaction.toString() === AccountAccess.Wire
      ) {
        const res = await getBankAccountByBankAllocationId({
          variables: {
            where: {
              bankAllocationId,
            },
          },
        });
        setOpenWireInstructions(true);
        const bankAccount = res.data.bankAccountByBankAllocationId;
        setBankAccountInfo({
          bankAccount,
          hilda,
          destination,
        });
      } else {
        triggerAlert({
          msg: "Your transfer has been recorded and will now be processed by our custodian.",
          title: SUCCESS,
          type: "success",
        });
        handleCloseDrawer();
      }
    } catch (e) {
      Sentry.captureException(e);
      triggerAlert({
        msg: "There was a problem with this request. Please try again or contact us for support.",
        title: ERROR,
        type: "error",
      });
      console.log(e);
    }
  };

  const handleBankAccountChange = (value: string) => {
    setBankAccountId(value);
  };

  const handleBankAllocationChange = (value: string) => {
    setBankAllocationId(value);
  };

  const handleAmountChange = (event: any) => {
    let value = event.target.value;
    setAmount(value);
  };

  const handleLinkBankClick = () => {
    localStorage.setItem("linkBankHildaId", params.hildaAccountId);
  };

  const getHiddenAccountNumber = (accountNumber: string) => {
    const arr = accountNumber.split("");
    return arr
      .map((letter, idx) => (idx < arr.length - 4 ? "*" : letter))
      .join("");
  };

  const mapItems = (items: any[], type?: string) => {
    return items.map((item) => {
      if (item.plaidBankName) {
        return {
          label: `${item.plaidBankName} - ${item.plaidAccountName.replace(
            "Plaid",
            ""
          )} - ${getHiddenAccountNumber(item.beneficiaryAccountNumber)}`,
          value: item.id,
        };
      } else if (item.beneficiaryAccountName) {
        return {
          label: `${
            item.bank
              ? (item.product.name.slice(
                  0,
                  item.product.name.indexOf("Cash")
                ) || item.beneficiaryAccountName) +
                " - " +
                item.bank.name +
                " - $" +
                formatCurrencyNumber(item.availableBalance)
              : type === TransferType.Destination
              ? "Wire - " +
                item.beneficiaryAccountName +
                " - " +
                getHiddenAccountNumber(item.beneficiaryAccountNumber)
              : "Wire"
          }`,
          value: item.id,
        };
      }
      return {
        label: `${
          item.product.name.slice(0, item.product.name.indexOf("Cash")) + //Formatting product name
          " -  " +
          item.product.bankName +
          " - $" +
          formatCurrencyNumber(item.availableNowAmount)
        }`,
        value: item.id,
      };
    });
  };

  useEffect(() => {
    if (source && amount) {
      const isWireOrPlaid = !!(
        source.beneficiaryAccountName === AccountAccessVal.Wire ||
        source.plaidAccountName
      );
      const accountHasFunds =
        amount &&
        parseFloat(amount.replace(/,/g, "")) <= source.availableBalance &&
        source.availableBalance !== 0;
      const shouldEnableSubmit = accountHasFunds || isWireOrPlaid;
      setEnableSubmit(shouldEnableSubmit);
    }
  }, [
    amount,
    source?.availableBalance,
    source?.beneficiaryAccountName,
    source?.plaidAccountName,
  ]);

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title}>Transfer</Typography>
        <img
          className={classes.closeIcon}
          src={clear}
          alt="Close drawer"
          onClick={handleCloseDrawer}
        />
      </div>
      <hr className={classes.divider} />
      <Typography className={classes.subtitle}>{TRANSFER_SUBTITLE}</Typography>
      <Grid container className={classes.grid}>
        <Grid container item lg={12} direction="column">
          <div className={classes.container}>
            <Typography className={classes.sourceTitle}>Source</Typography>
            {canLinkBankAccount && (
              <Button
                className={classes.sourceButton}
                onClick={handleLinkBankClick}
              >
                <Link to={linkBankAccountPath} className={classes.sourceText}>
                  + Link Your Bank
                </Link>
              </Button>
            )}
          </div>
          {loading ? (
            <Loading isLoading />
          ) : bankAccounts.length > 0 ? (
            <DropDown
              ellipsisSize={100}
              menuItems={bankAccounts}
              setValueFunc={handleBankAccountChange}
            />
          ) : (
            <span className={classes.noAccounts}>No accounts available</span>
          )}
        </Grid>
        <Grid container item lg={12} direction="column">
          <Typography className={classes.dropdownTitle}>Destination</Typography>
          {loading ? (
            <Loading isLoading />
          ) : bankAllocations.length > 0 ? (
            <DropDown
              ellipsisSize={100}
              menuItems={bankAllocations}
              setValueFunc={handleBankAllocationChange}
            />
          ) : (
            <span className={classes.noAccounts}>No accounts available</span>
          )}
        </Grid>
        <Grid container item lg={12} direction="column">
          <Typography className={classes.dropdownTitle}>Amount</Typography>
          <TextField
            id="amount"
            className={classes.textField}
            variant="filled"
            placeholder={"0.00"}
            InputProps={{
              disableUnderline: true,
              startAdornment: <InputAdornment position="end">$</InputAdornment>,
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleAmountChange}
          />
        </Grid>
        <Grid container item lg={12} direction="column">
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.buttonCancel}
              variant="outlined"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button
              className={classes.buttonSubmit}
              variant="contained"
              color="primary"
              disableElevation
              disabled={!enableSubmit}
              classes={{ disabled: classes.disabledButton }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
      {bankAccountInfo && (
        <WireModal
          bankAccountInfo={bankAccountInfo}
          openModal={openWireInstructions}
          setOpenModal={setOpenWireInstructions}
          handleCloseDrawer={handleCloseDrawer}
        />
      )}
    </>
  );
};

export default Transfer;
