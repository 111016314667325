import SideNav from '../../components/sideNav/sideNav';
import { viewsUseStyles } from '../views.styles';
import SelfApplyInfo from './selfApplyInfo/selfApplyWorkflow/selfApplyWorkflow';

const SelfApply = () => {
  const classes = viewsUseStyles();
  return (
    <div className={classes.globalViewContent}>
      <SelfApplyInfo />
    </div>
  );
};

export default SelfApply;
