import { Grid, ListItem, Typography } from '@material-ui/core';
import { Group } from '@mantine/core';
import { Auth } from 'aws-amplify';
import PersonIcon from '../../shared/icons/personIcon';
import {
  assistanceContact,
  landingWelcome,
} from '../../shared/utils/textConstants';
import { Typography as Icon } from '../../shared/theme/styles.enum';
import { useStyles } from './landing.styles';
import Link from '../../components/link/link';
import { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { gql, useLazyQuery } from '@apollo/client';

const Landing = () => {
  const url = window.location.href;
  const subdomainRegExp = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
  const subdomain = url.match(subdomainRegExp)?.[1];
  const classes = useStyles();

  useEffect(() => {
    try {
      const hostname = localStorage.getItem('hostname');
      if (hostname && process.env.REACT_APP_ENV !== 'local') {
        const path = `https://${hostname}.${
          window.location.host.split('.').slice(1).join('.') +
          window.location.pathname
          }`;
        if (window.location.href !== path) window.location.replace(path);
      }
    } catch (e) {
      Sentry.captureException(e)
      console.log(e);
    }
    // eslint-disable-next-line
  }, []);

  const GET_ADVISOR_FIRM_CONFIG = gql`
  query advisorFirmConfig($advisorSubdomain:String!){
    advisorFirmConfigNoAuth(advisorSubdomain:$advisorSubdomain){
      secondaryColor
    }
  }
  `;


  const getLogo = gql`
  query advisorFirmConfigLogo($advisorSubdomain:String!){
    advisorFirmConfigNoAuth(advisorSubdomain:$advisorSubdomain){
      logo
    }
  }
  `;

  const getHeaderAndParagraph = gql`
  query advisorFirmConfigHeaderParagraph($advisorSubdomain:String!){
    advisorFirmConfigNoAuth(advisorSubdomain:$advisorSubdomain){
      header,
      paragraph
    }
  }
  `;


  useEffect(()=>{
    getAdvisorConfig({variables:{
     advisorSubdomain: subdomain
   }})

   },[subdomain])

   useEffect(()=>{
    getAdvisorLogo({variables:{
     advisorSubdomain: subdomain
   }})

   },[subdomain])

   useEffect(()=>{
    getAdvisorHeaderandParagraph({variables:{
     advisorSubdomain: subdomain
   }})

   },[subdomain])


    const[NavBackgorundColor,setNavBackgorundColor] = useState("")
    const[firmLogo,sfirmLogo] = useState("")
    const[header,sheader] = useState("")
    const[paragraph,sparagraph] = useState("")

    const [getAdvisorConfig,{data:advisorConfigData}]=useLazyQuery(GET_ADVISOR_FIRM_CONFIG)
    const [getAdvisorLogo,{data:advisorLogoData}]=useLazyQuery(getLogo)
    const [getAdvisorHeaderandParagraph,{data:advisorHeaderandParagraphData}]=useLazyQuery(getHeaderAndParagraph)

    useEffect(()=>{
      sfirmLogo(advisorLogoData?.advisorFirmConfigNoAuth?.logo)
     },[advisorLogoData])

     useEffect(()=>{
      sheader(advisorHeaderandParagraphData?.advisorFirmConfigNoAuth?.header)
      sparagraph(advisorHeaderandParagraphData?.advisorFirmConfigNoAuth?.paragraph)

      },[advisorHeaderandParagraphData])

     useEffect(()=>{
      const paragraphContainer = document.getElementById("paragraphContainer")
      if(paragraphContainer !== null && paragraph!== undefined){

        paragraphContainer.innerHTML=String(paragraph)
      }
    },[paragraph])

    useEffect(()=>{
      const headerContainer = document.getElementById("headerContainer")
      if(headerContainer !== null && header!== undefined){

        headerContainer.innerHTML=String(header)
      }
    },[header])

   useEffect(()=>{
    setNavBackgorundColor(advisorConfigData?.advisorFirmConfigNoAuth?.secondaryColor)
   },[advisorConfigData])

   const signInEvent = async () => {
    try {
      await Auth.federatedSignIn();
    } catch (e) {
      Sentry.captureException(e)
      console.log(e);
    }
  };

  return (
    <Grid container className={classes.grid}>
      <Grid container item md={12} lg={6} direction="column">
      <h2 id="headerContainer" className={classes.header}></h2>
      <p  id="paragraphContainer"  className={classes.paragraph} >
        </p>
      </Grid>
      <Grid container item md={12} lg={6} direction="column">
        <div className={classes.container}>
          <img src={firmLogo}  alt="" className={classes.logo} />

          <Typography className={classes.title}>
            {landingWelcome("")}
          </Typography>
          <Group>
            <ListItem
              key={1}
              className={classes.buttonStyle}
              onClick={signInEvent}
            >
              <PersonIcon
                alt="person"
                className={classes.iconButton}
                size={Icon.BasicText}
              />
              <Typography className={classes.buttonText}>Log In</Typography>
            </ListItem>
            <Link key={2} to={'/selfApply'} className={classes.linkStyle} style={{backgroundColor:NavBackgorundColor,}}>
              <Typography className={classes.buttonText}>
                New Account
              </Typography>
            </Link>
          </Group>
        </div>
        <div className={classes.footerContainer}>
          <Typography className={classes.footer}>
            {assistanceContact}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Landing;
