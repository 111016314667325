//COMPANY NAME
export const companyName = "IFI Network";
export const landingWelcome = (name: string) => {
  return "Banking Platform";
};
export const assistanceContact =
  "For assistance please reach out to (801) 656-9652";

// PARAGRAPHS
export const DRAG_AND_DROP_HERE = "... or drag and drop your file here...";
export const ACCOUNT_PROCESS =
  "Our system will take 24 hours to process your account data once uploaded.";
export const LOAD_CLIENTS_CRM = "Load clients from any CRM output data";
export const ACCOUNTS_UPLOADS_TIPS = [
  "1. We support all CRM systems and file formats however we suggest CSV or Excel for fastest processing",
  "2. Data must at a minimum include legal account name, and email address",
  "3. The more information included the less information your clients require when submitting their IFI application",
];

//STATEMENT PDF
export const CUSTOMER_SERVICE_TITLE1 = "QUESTIONS?";
export const CUSTOMER_SERVICE_SUBTITLE1 = "You may call (801) 656-9652";
export const CUSTOMER_SERVICE_TITLE2 = "OR, YOU CAN WRITE:";
export const CUSTOMER_SERVICE_SUBTITLE2 = [
  "IFI Client Services",
  "2825 E Cottonwood Parkway,",
  "Suite 500",
  "Salt Lake City, Utah 84121",
];
export const CUSTOMER_SERVICE_TITLE3 = "IN CASE OF ERRORS: ";
export const CUSTOMER_SERVICE_SUBTITLE3_1 =
  "If you think your statement or record is wrong or if you need more information about a transfer on the statement or record, telephone us or write to us at the address shown in the Customer Service Information section on your statement as soon as possible. We must hear from you no later than 60 days after we sent you the first statement on which the error or problem appeared. You are entitled to remedies for error resolution for an electronic fund transfer in accordance with the Electronic Fund Transfer Act and federal Regulation E or in accordance with laws of the state where your account is located as may be applicable. Give us the following information: ";
export const CUSTOMER_SERVICE_SUBTITLE3_2 = [
  "1. Your name and account number.",
  "2. The dollar amount of the suspected error.",
  "3. Describe the error or the transfer you are unsure about and explain as clearly as you can why you believe there is an error or why you need more information.",
];
export const CUSTOMER_SERVICE_SUBTITLE3_3 =
  "We will investigate your complaint and will correct any error promptly.";
export const DISCLOSURES_TITLE1 = " The balances ";
export const DISCLOSURES_PARAGRAPH1 =
  "in your account are not FDIC Insured. High Yield Cash Account products are not members of the Federal Deposit Insurance Corporation (“FDIC”), but the banks in which the money is deposited may be FDIC members. The FDIC is an independent agency of the U.S. government that protects the funds depositors place in FDIC insured institutions.";
export const DISCLOSURES_TITLE2 = " Net Accrued Interest ";
export const DISCLOSURES_PARAGRAPH2 =
  "is the estimated amount of the accrued interest earned for the current month less any fees with respect to such interest. Net Accrued Interest cannot be withdrawn from the account until it is posted as a transaction to the account. There is no guarantee that the account holder will receive the full amount as stated. Net Accrued Interest is for informational purposes only.";

// ALERTS
export const SUCCESS = "Success";
export const WARNING = "Warning";
export const ERROR = "Error";
export const IF_FAILURE_TEXT =
  "If failure occurs, please provide consent for our technical leads to work with you directly on data issues.";

export const UPLOAD_SUCCESS_MSG =
  "Your file is being processed. Please check back in 24 hours.";
export const UPLOAD_WARNING_MSG = "There may be an issue with this file.";
export const UPLOAD_TOO_BIG =
  "This file is too big to be uploaded. Max upload size is 9 megabytes.";
export const UPLOAD_ERROR_MSG =
  "There was a problem uploading your file. Please try again or contact us for support.";
export const DEFAULT_UPLOAD_COMMENT = "There are no comments for this file.";

export const SUCCESS_ONBOARDING_MSG = (type: "create" | "update") =>
  type === "create"
    ? "This account has been successfully created."
    : "This account has been successfully updated.";

export const ERROR_ONBOARDING_MSG = (type: "create" | "update") =>
  type === "create"
    ? "There was a problem creating the account."
    : "There was a problem updating the account.";

export const PERSONAL_URL = "Personal URL";
export const COPIED_URL = "URL Copied";
export const AVAILABLE_NOW = "Available Now:";
export const PENDING_TRANSACTIONS = "Pending Transactions:";

export const UPLOAD_SUCCESS = "Upload Success";
export const UPLOAD_WARNING = "Upload Warning";
export const UPLOAD_FAILURE = "Upload Failure";

export const IDLE_TITLE = "Idle Warning";
export const IDLE_MESSAGE =
  "It appears you are inactive. You will be logged out in 5 minutes";

export const SUCCESS_ADVISOR_CONFIG = `Successfully updated your Advisor Firm's Configuration`;
export const ERROR_ADVISOR_CONFIG = `There was a problem updating your Advisor Firm's Configuration`;

export const COPIED_TO_CLIPBOARD = "Copied to clipboard!";

//BUTTONS
export const BUTTON_FILTER_BY = "Filter By";
export const BUTTON_CANCEL = "Cancel";
export const BUTTON_APPLY = "Apply";

//FORMS
export const ACCOUNT_TYPE_TITLE = "Type of account";
export const PERSONAL_INFORMATION_TITLE = "Personal information for";
export const ENTITY_INFORMATION_TITLE = "Entity Information for";
export const PERSONAL_INFORMATION = "Personal Information";
export const PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_PRIMARY =
  "Personal information: Primary person";
export const PERSONAL_INFORMATION_JOINT_ACCOUNT_TITLE_SECONDARY =
  "Personal information: Secondary person";
export const PERSONAL_INFORMATION_TRUST_ACCOUNT_REPRESENTATION_TITLE =
  "Account Owner Representation As Not A U.S. Employee Retirement Income Security Act Plan Asset*";
export const PERSONAL_INFORMATION_TRUST_ACCOUNT_REPRESENTATION =
  "The Account Owner and the person making the decision on behalf of such Account Owner to make a deposit in a FICA Account (if any) represents, warrants and certifies, by checking the box below, that on each date on which the Account Owner has a deposit in a FICA Account, the Account Owner is not subject to Title I of the Employee Retirement Income Security Act of 1974, as amended (“ERISA”), Section 4975(e)(1) of the Internal Revenue Code of 1986, as amended (the “Code”), or in the case of any governmental employee benefit plan, not subject to any law, statute, regulation, provision or other governing provision which is similar to Section 406 of ERISA or Section 4975 of the Code";
export const AGREE_STATEMENT = "I agree to the statement above.";
export const PERSONAL_INFORMATION_TRUST_ACCOUNT_AGREMENT = `The IFI (“IFI”) Program allows customers to direct IFI to deposit funds into one or more Depository Bank Programs (as defined below) via the convenience of a single custodial account (“IFI Account”). In opening an IFI Account, you agree to the Terms and Conditions set forth on your application sent separately.
  
The funds in your IFI Account will be held by The Northern Trust Company (“Northern Trust”) which will be acting as your custodian and as the custodian for the IFI program (the “IFI Program”). The Northern Trust Company, in its capacity as the IFI Program custodian, is referred to herein as the “IFI Custodian.” By opening an IFI Account, you are appointing IFI as your authorized agent as described herein.
  
Upon your instructions, IFI will direct the IFI Custodian to deposit funds from your IFI Account into one or more deposit accounts (each, a “Depository Account”) at selected banks (each a “Depository Bank”). Depository Account(s) will be in the name of one or more sub-custodians (each, as “Sub-Custodian”) acting as custodian for the persons or entities who are listed on its records.
  
Funds in the IFI Program may be deposited in Depository Banks which are not FDIC member banks, or in FDIC member banks in amounts beyond the applicable limits for FDIC deposit insurance coverage. FUNDS DEPOSITED THROUGH YOUR IFI ACCOUNT IN A DEPOSITORY ACCOUNT WITH A DEPOSITORY BANK WHICH IS NOT AN FDIC MEMBER BANK, OR DEPOSITED WITH AN FDIC MEMBER BANK IN AN AMOUNT IN EXCESS OF THE APPLICABLE LIMIT FOR FDIC DEPOSIT INSURANCE COVERAGE (CURRENTLY $250,000.00), ARE NOT INSURED OR GUARANTEED BY THE FEDERAL DEPOSIT INSURANCE CORPORATION OR ANY OTHER GOVERNMENT AGENCY.`;
export const ACCOUNT_ACCESS_TITLE = "Withdrawal Instructions";
export const ACCOUNT_ACCESS_FOOTER_TITLE = "View Access For Your Advisor";
export const ACCOUNT_ACCESS_ADVISOR =
  "By submitting this application, you are giving your advisor access to view your account information. Please continue only if you agree to grant them access. They will have access to transact on your behalf unless an LPOA is signed and submitted by the account owner. Please reach out to your advisor for more information:";
export const ACCOUNT_ACCESS_NONADVISOR =
  "By submitting this application, you are giving your advisor access to view you account information. Please continue only if you agree to grant them access. They will have access to transact on your behalf unless an LPOA is signed and submitted by the account owner. Please reach out to your advisor for more information.";
export const TERMS_AND_CONDITIONS = `The IFI (“IFI”) Program allows customers to direct IFI to deposit funds into one or more Depository Bank Programs (as defined below) via the convenience of a single custodial account (“IFI Account”). In opening an IFI Account, you agree to the Terms and Conditions set forth on your application sent separately.
  
The funds in your IFI Account will be held by The Northern Trust Company (“Northern Trust”) which will be acting as your custodian and as the custodian for the IFI program (the “IFI Program”). The Northern Trust Company, in its capacity as the IFI Program custodian, is referred to herein as the “IFI Custodian.” By opening an IFI Account, you are appointing IFI as your authorized agent as described herein.
  
Upon your instructions, IFI will direct the IFI Custodian to deposit funds from your IFI Account into one or more deposit accounts (each, a “Depository Account”) at selected banks (each a “Depository Bank”). Depository Account(s) will be in the name of one or more sub-custodians (each, as “Sub-Custodian”) acting as custodian for the persons or entities who are listed on its records.
  
Funds in the IFI Program may be deposited in Depository Banks which are not FDIC member banks, or in FDIC member banks in amounts beyond the applicable limits for FDIC deposit insurance coverage. FUNDS DEPOSITED THROUGH YOUR IFI ACCOUNT IN A DEPOSITORY ACCOUNT WITH A DEPOSITORY BANK WHICH IS NOT AN FDIC MEMBER BANK, OR DEPOSITED WITH AN FDIC MEMBER BANK IN AN AMOUNT IN EXCESS OF THE APPLICABLE LIMIT FOR FDIC DEPOSIT INSURANCE COVERAGE (CURRENTLY $250,000.00), ARE NOT INSURED OR GUARANTEED BY THE FEDERAL DEPOSIT INSURANCE CORPORATION OR ANY OTHER GOVERNMENT AGENCY.`;
export const AGREE_TERMS_AND_CONDITIONS =
  "I agree to the Terms and Conditions.";
export const SIGNATURE_TITLE = "Certification";
export const SIGNATURE_SUBTITLE =
  "I agree that by clicking “Submit”, and entering my name and date, I am providing the legal equivalent of my handwritten signature and that doing so shows my intent to sign the new account application and certification. I agree to print and/or save a copy of this certification for my records. This binding contract is effective as of today’s date, and is binding on myself, any joint account holder, and any successor account. By pressing the Submit button, a the new account will be created.";
export const IFI_NETWORK_URL = ".ifi.network";
export const CODE_INPUT = "Code here";

//EMBED CODE
export const GET_EMBED_CODE_BUTTON = (url: string, color: string) => `
<a href="${url}" target="_blank">
<button style="width: 134px; height: 32px; padding: 5px 23px 4px; 
  border: none; border-radius: 3px; background-color: ${color}; 
  cursor: pointer;"
>
<span style="width: 58px; height: 22px; font-size: 16px; font-weight: 600; 
  font-stretch: normal; font-style: normal; line-height: normal; 
  letter-spacing: normal; text-align: center; color: #ffffff;"
>IFI Network</span>
</button>
</a>`;

//TRANSFER
export const TRANSFER_SUBTITLE =
  "Always double check that your source account has enough funds to cover your transaction.";
