import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import { ChangeEvent, useEffect, useState } from 'react';
import { AccountAccess } from '../../../shared/interfaces/accountAccess.enum';
import { FormTypes } from '../../../shared/interfaces/formTypes.enum';
import {
  Errors,
  IAccountAccessFields,
  Types,
} from '../../../shared/interfaces/iform';
import { isFormInputValid } from '../../../shared/utils/formUtils';
import {
  ACCOUNT_ACCESS_TITLE,
  ACCOUNT_ACCESS_ADVISOR,
  ACCOUNT_ACCESS_FOOTER_TITLE,
  ACCOUNT_ACCESS_NONADVISOR,
} from '../../../shared/utils/textConstants';
import { useStyles } from './accountAccess.styles';

const errorsDefault: Errors = {
  bankABA: false,
  beneficiaryAccountName: false,
  beneficiaryAccountNumber: false,
};

const types: Types = {
  bankABA: FormTypes.AccountNumber,
  beneficiaryAccountName: FormTypes.Text,
  beneficiaryAccountNumber: FormTypes.AccountNumber,
};

interface IProps {
  id: string;
  formFields: IAccountAccessFields;
  isAdvisor: boolean;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => void;
}

const AccountAccessForm = (props: IProps) => {
  const {
    id,
    formFields,
    isAdvisor,
    handleSetStepCanAdvance,
    handleValueChange,
  } = props;
  const [errors, setErrors] = useState(errorsDefault);
  const classes = useStyles();

  useEffect(() => {
    validateSelection();
    // eslint-disable-next-line
  }, [formFields.accountAccess]);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    validateNextStep();
  };

  const validateSelection = () => {
    if (formFields.accountAccess === AccountAccess.ACH) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  const validateNextStep = () => {
    const isFormValid =
      Object.entries(types).filter(
        ([key, value]) => !isFormInputValid(value, formFields[key])
      ).length === 0;
    if (isFormValid) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  return (
    <div>
      <div className={classes.title}>{ACCOUNT_ACCESS_TITLE}</div>
      <br />
      <div className={classes.instructions}>
        <Typography className={classes.text}>Remember:</Typography>
        <ol className={classes.list}>
          <li>
            <b>ACH</b> are safer.
          </li>
          <li>
            <b>Wires</b> may incur fees at your receiving bank and should be
            used only with larger balance accounts or brokerage accounts.
          </li>
        </ol>
      </div>
      <FormControl className={classes.radioGroup} component="fieldset">
        <RadioGroup
          aria-label="accountAccess"
          id="accountAccess"
          name="accountAccess"
          value={formFields.accountAccess}
          onChange={(e) => handleValueChange(e, types[e.target.name])}
        >
          <FormControlLabel
            value={AccountAccess.ACH}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label={
              <Typography className={classes.text}>
                ACH &nbsp; &nbsp; &nbsp;
                {formFields.accountAccess === AccountAccess.ACH && (
                  <Typography className={classes.primaryColor}>
                    (Great choice. You can link a bank account later after you
                    submit your application)
                  </Typography>
                )}
              </Typography>
            }
          />
          <FormControlLabel
            value={AccountAccess.Wire}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label="Wire"
          />
        </RadioGroup>
      </FormControl>
      {formFields.accountAccess === AccountAccess.Wire && (
        <FormControl className={classes.wireForm} component="fieldset">
          <TextField
            id="bankABA"
            name="bankABA"
            label="Bank ABA"
            variant="outlined"
            required
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            inputProps={{ maxLength: 9 }}
            error={errors.bankABA}
            value={formFields.bankABA}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
          />
          <TextField
            id="beneficiaryAccountName"
            name="beneficiaryAccountName"
            label="Beneficiary Account Name"
            variant="outlined"
            required
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            inputProps={{ maxLength: 24 }}
            error={errors.beneficiaryAccountName}
            value={formFields.beneficiaryAccountName}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
          />
          <TextField
            id="beneficiaryAccountNumber"
            name="beneficiaryAccountNumber"
            label="Beneficiary Account Number"
            variant="outlined"
            required
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            inputProps={{ maxLength: 9 }}
            error={errors.beneficiaryAccountNumber}
            value={formFields.beneficiaryAccountNumber}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
          />
          <TextField
            id="ffcffbName"
            name="ffcffbName"
            label="FFC/FFB Name (Optional)"
            variant="outlined"
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            value={formFields.ffcffbName}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
          />
          <TextField
            id="ffcffbName2"
            name="ffcffbName2"
            label="FFC/FFB Name (Optional)"
            variant="outlined"
            className={classes.wireTextField}
            InputLabelProps={{
              className: classes.wireTextFieldInput,
            }}
            size="small"
            value={formFields.ffcffbName2}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
          />
        </FormControl>
      )}
      <hr className={classes.divider} />
      <div className={classes.footer}>
        <Typography className={classes.title}>
          {ACCOUNT_ACCESS_FOOTER_TITLE}
        </Typography>
        <br />
        {isAdvisor ? (
          <div>
            <Typography className={classes.colorTextGrey}>
              {ACCOUNT_ACCESS_ADVISOR}
            </Typography>
            <br />
            <Typography>
              <Typography className={classes.colorTextGrey}>
                Advisor:
              </Typography>{' '}
              Kyle McAndrew
            </Typography>
            <Typography>
              <Typography className={classes.colorTextGrey}>Phone:</Typography>{' '}
              948-635-6548
            </Typography>
            <Typography>
              <Typography className={classes.colorTextGrey}>Email:</Typography>
              kmcAcndrew@avalonadvisors.com
            </Typography>
          </div>
        ) : (
          <Typography className={classes.colorTextGrey}>
            {ACCOUNT_ACCESS_NONADVISOR}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AccountAccessForm;
