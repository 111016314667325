import React, { useContext, useEffect, useState } from "react";
import { IAccountsSearch } from "../interfaces/iacountsSearch.interface";
import { IUser } from "../interfaces/iuser.interface";
import useDynamicTheme from "../theme/useDynamicTheme";
import useUserData from "./useUserData";
import { Auth } from "aws-amplify";
import { useIdleTimer } from "react-idle-timer";
import { triggerAlert } from "../../components/toast/toast";
import { IDLE_MESSAGE, IDLE_TITLE } from "../utils/textConstants";
import { ThemeContext } from "./theme.context";

export type IAuthState = "signedIn" | "signedOut" | "loading";

export interface IUserData {
  user: IUser;
  authState: IAuthState;
  reRoute: string;
  accountsSearch: IAccountsSearch;
  logo: string;
  companyName: string;
  subDomainName: string;
}
export interface IUserContext {
  userData: IUserData;
  setUserData: React.Dispatch<React.SetStateAction<IUserData>>;
}

export const defaultUserData: IUserData = {
  user: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    advisorFirmId: "0",
    role: {
      id: 0,
      name: "",
      permissions: [""],
    },
  },
  authState: "loading",
  reRoute: "/",
  accountsSearch: {
    searchValue: "",
    checkboxItems: [],
    accordionItems: [],
    accountList: [
      {
        description: "",
        accounts: [],
        count: 0,
      },
    ],
  },
  logo: "",
  companyName: "",
  subDomainName: "",
};

export const defaultContext: IUserContext = {
  userData: defaultUserData,
  setUserData: () => {},
};

export const UserContext = React.createContext(defaultContext);

const UserContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const { setTheme } = useContext(ThemeContext);
  const userContext: IUserContext = useUserData();
  const [theme] = useDynamicTheme(userContext.userData);
  const timeout = 600000; //10 Minutes
  const halftime = timeout / 2;
  const [remaining, setRemaining] = useState(timeout);

  useEffect(() => {
    if (theme) {
      setTheme(theme);
    }
  }, [theme, setTheme]);

  const handleOnIdle = async () => {
    await Auth.signOut();
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());
    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 300000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userContext.userData.authState === "signedIn") {
      if (remaining <= halftime + 60000 && remaining >= halftime - 60000) {
        triggerAlert({
          type: "warning",
          title: IDLE_TITLE,
          msg: IDLE_MESSAGE,
        });
      }
    }
    // eslint-disable-next-line
  }, [remaining]);

  if (userContext.userData.authState === "loading") {
    return null;
  }
  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
