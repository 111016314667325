import { SvgIcon } from '@material-ui/core';

const IFILogo = (props: {
  alt: string;
  maincolor: string;
  className?: any;
}) => {
  return (
    <SvgIcon
      {...props}
      id="Group_17"
      data-name="Group 17"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 768"
      className={props.className}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M874.151 383.502V708.004H918.076H962V383.502V59H918.076H874.151V383.502Z" fill={props.maincolor} />
      <path d="M323.753 164.777V111.888V59H377.538H431.323V111.888V164.777H377.538H323.753Z" fill="#19CEAF" />
      <path d="M460.008 164.777V111.888V59H512.896H565.785V111.888V164.777H512.896H460.008Z" fill="#19CEAF"/>
      <path d="M594.47 164.777V111.888V59H647.359H700.247V111.888V164.777H647.359H594.47Z" fill="#19CEAF"/>
      <path d="M324.173 249.063C323.911 220.973 324.086 196.971 324.565 195.726C325.237 193.973 337.395 193.462 378.404 193.462H431.377L430.901 246.799L430.426 300.135H377.538H324.649L324.173 249.063Z" fill="#19CEAF"/>
      <path d="M324.173 383.525C323.911 355.435 324.086 331.433 324.565 330.189C325.237 328.435 337.389 327.924 378.379 327.924H431.323V381.736V435.548L377.986 435.073L324.649 434.598L324.173 383.525Z" fill="#19CEAF"/>
      <path d="M460.008 435.494V381.709V327.924H512.896H565.785V381.709V435.494H512.896H460.008Z" fill="#19CEAF"/>
      <path d="M594.47 435.494V381.709V327.924H647.359H700.247V381.709V435.494H647.359H594.47Z" fill="#19CEAF"/>
      <path d="M323.753 517.367C323.753 488.442 324.303 464.228 324.974 463.555C325.646 462.883 349.649 462.548 378.311 462.808L430.426 463.283L430.901 516.62L431.377 569.956H377.565H323.753V517.367Z" fill="#19CEAF"/>
      <path d="M323.753 704.418V651.53V598.641H377.538H431.323V651.53V704.418H377.538H323.753Z" fill="#19CEAF"/>
      <path d="M62 708.004V383.502V59H105.924H149.849V383.502V708.004H105.924H62Z" fill={props.maincolor} />
    </SvgIcon>
  );
};

export default IFILogo;
