import { Button, Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import WireTransferIcon from '../../shared/icons/wireTransferIcon';
// import { SUCCESS } from '../../shared/utils/textConstants';
// import { triggerAlert } from '../toast/toast';
import { useStyles } from './wireModal.styles';

interface IWireTransferProps {
  bankAccountInfo: any;
  openModal: boolean;
  setOpenModal: any;
  handleCloseDrawer: () => void;
}

const WireModal = (props: IWireTransferProps) => {
  const { bankAccountInfo, openModal, setOpenModal, handleCloseDrawer } = props;
  const bankAccount = bankAccountInfo.bankAccount;
  const hilda = bankAccountInfo.hilda;
  const destination = bankAccountInfo.destination;
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
    // triggerAlert({
    //   msg: 'Your request has been marked as pending',
    //   title: SUCCESS,
    //   type: 'success',
    // });
    handleCloseDrawer();
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.main}
      >
        <div className={classes.container}>
          <div className={classes.colorBackground}>
            <WireTransferIcon
              alt="wire"
              className={classes.wireIcon}
              size={'5.5rem'}
            />
          </div>
          <div className={classes.wireInstructionsContainer}>
            <Typography className={classes.title}>Wire Instructions</Typography>
            <Typography className={classes.subtitle}>
              Please provide the below wire instructions to your bank or
              brokerage firm:
            </Typography>
            <div className={classes.wireInformationContainer}>
              <Grid container className={classes.grid} spacing={1}>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.text}>Bank ABA:</Typography>
                    <hr className={classes.line} />
                  </div>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <Typography className={classes.textData}>
                    {bankAccount && bankAccount.accountRef}
                  </Typography>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.text}>
                      Beneficiary account name:
                    </Typography>
                    <hr className={classes.line} />
                  </div>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <Typography className={classes.textData}>
                    {bankAccount && bankAccount.beneficiaryAccountName}
                  </Typography>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.text}>
                      Beneficiary account number:
                    </Typography>
                    <hr className={classes.line} />
                  </div>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <Typography className={classes.textData}>
                    {bankAccount && bankAccount.beneficiaryAccountNumber}
                  </Typography>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.text}>
                      FFC/FFB name:
                    </Typography>
                    <hr className={classes.line} />
                  </div>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <Typography className={classes.textData}>
                    {hilda && hilda.name}
                  </Typography>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <div style={{ display: 'flex' }}>
                    <Typography className={classes.text}>
                      FFC/FFB account:
                    </Typography>
                    <hr className={classes.line} />
                  </div>
                </Grid>
                <Grid container item sm={6} md={6} lg={6} direction="column">
                  <Typography className={classes.textData}>
                    {hilda &&
                      destination &&
                      `${hilda.id}-${destination.product.shortName}${
                        destination.product.shortName === "HYBA"
                          ? destination.bankAccountId
                          : ""
                      }`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.wireButtonContainer}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleClose}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WireModal;
