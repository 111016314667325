import { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { useStyles } from './stepperContainer.styles';
import WorkflowTimeline, { IStep } from './workflowTimeline';

interface IStepperProps {
  steps: IStep[];
  startOver: boolean;
  clearFormFields: () => void;
  handleSubmit: () => void;
  submitSuccess: boolean;
}

const StepperContainer = (props: IStepperProps) => {
  const { steps, startOver, clearFormFields, handleSubmit } =
    props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    clearFormFields();
  };

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          IFI Account Application
        </Typography>
        {startOver && (
          <Button
            variant="outlined"
            onClick={handleReset}
            className={classes.buttonReset}
          >
            Start Over
          </Button>
        )}
      </div>
      <div className={classes.stepperBox}>
        <WorkflowTimeline active={activeStep} steps={steps} />
        <div className={classes.container}>
          <div>
            <Typography className={classes.content}>
              {steps[activeStep].form}
            </Typography>
            <div className={classes.buttonsContainer}>
              {activeStep === 0 || !steps[activeStep].buttonBack ? (
                <div className={classes.buttonHidden} />
              ) : (
                <Button
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.buttonBack}
                >
                  Back
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className={classes.buttonNext}
                  disableElevation
                  disabled={!steps[activeStep].nextValidated}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.buttonNext}
                  disableElevation
                  disabled={!steps[activeStep].nextValidated}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepperContainer;
