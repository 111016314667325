import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import {
  mapToColumns,
  getDateMonth,
  mapRenderCell,
} from '../../../shared/utils/gridUtils';
import TableHeader from '../../tableHeader/tableHeader';
import { useStyles } from './statementsTable.styles';
import { useQuery, NetworkStatus } from '@apollo/client';
import { GET_STATEMENTS_BY_BANK_ALLOCATION_ID } from './statementsTable.service';
import { IStatement } from '../../../shared/interfaces/istatement.interface';
import moment, { now } from 'moment';
import Loading from '../../loading/loading';
import PDFActionButtons from '../../statementPDF/pdfActionButtons';
import  {
  useDeepCompareEffectNoCheck,
} from '../../../shared/hooks/use-deep-compare-effect.hook';

const initialPageSize = 5;

interface IProps {
  amount: string;
  bankAllocationId: string;
}
const defaultStart = moment(now()).startOf('month').subtract(6, 'months');
const defaultEnd = moment(now()).startOf('month');
const defaultDates = {
  dateParam: 'postedDate',
  range: [defaultStart.format('YYYY-MM-DD'), defaultEnd.format('YYYY-MM-DD')],
};

const StatementsTable = (props: IProps) => {
  const { amount, bankAllocationId } = props;
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [withinDates, setWithinDates] = useState<{
    dateParam: string;
    range: string[];
  }>(defaultDates);
  const classes = useStyles();

  const { data, loading, error, networkStatus, refetch } = useQuery(
    GET_STATEMENTS_BY_BANK_ALLOCATION_ID,
    {
      variables: {
        where: {
          bankAllocationId,
        },
        countWhere: {
          bankAllocationId,
        },
        searchCriteria: {
          withinDates,
        },
        countSearchCriteria: {
          withinDates,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [amount, bankAllocationId]);

  useDeepCompareEffectNoCheck(() => {
    refetch({
      where: {
        bankAllocationId,
      },
      countWhere: {
        bankAllocationId,
      },
      searchCriteria: {
        withinDates,
      },
      countSearchCriteria: {
        withinDates,
      },
    });
    // eslint-disable-next-line
  }, [withinDates]);

  useDeepCompareEffectNoCheck(() => {
    if (dateRange.length === 0) {
      setWithinDates(defaultDates);
    } else if (dateRange.length === 2) {
      setWithinDates({ dateParam: 'postedDate', range: dateRange });
    }
    // eslint-disable-next-line
  }, [dateRange]);

  if (loading || networkStatus === NetworkStatus.fetchMore)
    return (
      <>
        <TableHeader
          title='Statement Report'
          yearMonthOnly
          setDateRange={setDateRange}
          defaultStart={defaultStart}
          defaultEnd={defaultEnd}
        />
        <div className={classes.container}>
          <Loading isLoading />
        </div>
      </>
    );

  if (error) return null;

  const statements: IStatement[] = data.statementsByBankAllocationId;
  const count = data.statementsCountByBankAllocationId;

  if (statements.length === 0)
    return (
      <>
        <TableHeader
          title="Statement Report"
          yearMonthOnly
          setDateRange={setDateRange}
        />
        <div className={classes.container}>
          <h4 className={classes.noRows}>No rows to display</h4>
        </div>
      </>
    );

  // Map Columns and add action column
  const arr = mapToColumns(
    statements.map((s) => {
      return { ...s, action: '' };
    })
  );

  const valueGetter = (params: GridValueGetterParams) => {
    const row = params.row;
    if (params.field === 'createdAt') {
      return getDateMonth(row.createdAt);
    }
    return params.value;
  };

  const columnsWithRender = mapRenderCell(
    arr,
    (params: GridRenderCellParams) => {
      if (params.field === 'action') {
        return (
          <PDFActionButtons
            date={moment
              .utc(params.row.createdAt)
              .format('YYYY-MM-DD')
              .toString()}
          />
        );
      }
      return <p>{params.value}</p>;
    }
  );

  const sortComparator = (v1: any, v2: any) => {
    const date1 = new Date(v1);
    const date2 = new Date(v2);
    return moment(date1).isAfter(date2) ? 1 : -1;
  };

  const columns: GridColumns = columnsWithRender.map((column, idx) => {
    if (idx === arr.length - 1) {
      return {
        ...column,
        headerAlign: 'right',
        align: 'right',
        valueGetter,
      };
    }
    if (column.field === 'createdAt') {
      return {
        ...column,
        valueGetter,
        type: 'date',
        sortComparator,
        headerName: 'Month',
      };
    } else {
      return { ...column, valueGetter };
    }
  });

  const rows = statements.map((s, idx) => {
    return { ...s, id: idx };
  });

  return (
    <>
      <TableHeader
        title="Statement Report"
        yearMonthOnly
        setDateRange={setDateRange}
        defaultStart={defaultStart}
        defaultEnd={defaultEnd}
      />
      <div className={classes.container}>
        <DataGrid
          rowCount={count}
          columns={columns}
          rows={rows}
          disableColumnMenu
          classes={{
            columnHeader: classes.header,
            cell: classes.cell,
          }}
          style={{ border: 'none' }}
          rowsPerPageOptions={[initialPageSize, 25, 50, 100]}
          hideFooterSelectedRowCount
        />
      </div>
    </>
  );
};

export default StatementsTable;
