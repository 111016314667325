import { gql } from "@apollo/client";

export const GET_BANK_ACCOUNTS_AND_ALLOCATIONS = gql`
  query BankAccountsAndAllocations(
    $hildaId: BigInt!
    $whereHilda: WhereHilda!
    $hildaWhereHilda: WhereHilda!
  ) {
    bankAccounts(hildaId: $hildaId) {
      id
      plaidBankId
      plaidBankName
      plaidAccountName
      plaidAccountOfficialName
      plaidAccountSubtype
      plaidAccountType
      accountRef
      balance
      bank {
        name
      }
      beneficiaryAccountName
      beneficiaryAccountNumber
    }
    productSummaries(whereHilda: $whereHilda) {
      bankAllocations {
        id
        bankAccountId
        amount
        availableNowAmount
        product {
          name
          bankName
          shortName
        }
      }
      bankAccounts {
        id
        accountRef
        balance
        availableBalance
        bank {
          name
        }
        product {
          id
          name
          shortName
          bankName
        }
        beneficiaryAccountName
        beneficiaryAccountNumber
        ffcffb1
        ffcffb2
      }
    }
    hilda(whereHilda: $hildaWhereHilda) {
      id
      name
    }
  }
`;

export const GET_BANK_ACCOUNT_BY_BANK_ALLOCATION_ID = gql`
  query BankAccountByBankAllocationId($where: BankAllocationTableWhere!) {
    bankAccountByBankAllocationId(where: $where) {
      accountRef
      beneficiaryAccountName
      beneficiaryAccountNumber
      ffcffb1
      ffcffb2
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation Mutation($createTransactionInput: CreateTransactionInput!) {
    createTransaction(createTransactionInput: $createTransactionInput)
  }
`;
