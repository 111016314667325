import { useContext , useEffect, useMemo, useState} from 'react';
import { UserContext } from '../../shared/context/userContext';
import UserOptions from './userOptions/userOptions';
import { AppBar, CssBaseline, useTheme } from '@material-ui/core';
import { useStyles } from './header.styles';
import MenuOptions from '../menu/menu';
import { MenuIndex } from '../menu/menu.enum';
import IFILogo from '../../shared/icons/ifiLogo';
import { gql, useLazyQuery } from '@apollo/client';

export const GET_ADVISOR_FIRM_CONFIG = gql`
query advisorFirmConfig($advisorSubdomain:String!){
  advisorFirmConfigNoAuth(advisorSubdomain:$advisorSubdomain){
    secondaryColor
  }
}
`;

interface IHeaderProps {
  children: any;
}

const Header = (props: IHeaderProps) => {
  const { userData } = useContext(UserContext);
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();

  const[NavBackgorundColor,setNavBackgorundColor] = useState("")


 const subdomain=useMemo(()=>{
  let text = ""
  text=window.location.hostname.split('.')[0]
  return text
 },[])

 const [getAdvisorConfig,{data:advisorConfigData}]=useLazyQuery(GET_ADVISOR_FIRM_CONFIG,)

 useEffect(()=>{
  setNavBackgorundColor(advisorConfigData?.advisorFirmConfigNoAuth?.secondaryColor)
  },[advisorConfigData])

  useEffect(()=>{
   getAdvisorConfig({variables:{
    advisorSubdomain: subdomain
  }})

  },[subdomain])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={0} className={classes.appBar} position="fixed" style={{backgroundColor:NavBackgorundColor}}>
        <div className={classes.box}>
          <div className={classes.logoContainer}>
            <IFILogo
              className={classes.ifiLogo}
              alt="IFI Logo"
              maincolor={theme.palette.secondary.contrastText}
            />
          </div>
          <div className={classes.optionsContainer}>
            <div className={classes.menuOptionsContainer}>
              <MenuOptions
                type={MenuIndex.TopNav}
                orientation="horizontal"
                typography="inherit"
                navLinkStyle={classes.menu}
                navLinkActiveStyle={classes.menuActive}
                itemStyle={classes.menuItem}
                permissions={userData.user.role.permissions}
              />
            </div>
            <div className={classes.selectMenuContainer}>
              <UserOptions />
            </div>
          </div>
        </div>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Header;
