import { useContext, useState } from "react";
import { Drawer } from "@material-ui/core";
import { useStyles } from "./sideNav.styles";
import LogoDropDown from "./logoDropDown/logoDropDown";
import { UserContext } from "../../shared/context/userContext";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ADVISOR_FIRM_DROPDOWN,
  UPDATE_SUPPORT_ADMIN_FIRM,
} from "./sideNav.service";
import { Roles } from "../../shared/interfaces/roles.enum";
import { IAdvisorFirm } from "../../shared/interfaces/iadvisorFirm.interface";
import { getIsRouteAuthenticated } from "../../shared/utils/auth";
import { getPath, RouteName } from "../../shared/routes/routeList";
import { useHistory } from "react-router-dom";
import useHostname from "../../shared/context/useHostname";
import { GlobalMessageContext } from "../../shared/context/globalMessage.context";
interface ISideNavProps {
  menu?: JSX.Element;
}

const SideNav = (props: ISideNavProps) => {
  const { showMessage } = useContext(GlobalMessageContext);
  const { userData } = useContext(UserContext);
  const { changeHostname } = useHostname(userData.subDomainName);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const permissions = userData.user.role.permissions;
  const canChangeFirm =
    userData.user.role.name === Roles.SupportAdmin &&
    getIsRouteAuthenticated(permissions, "supportAdmin");
  const history = useHistory();

  const { data } = useQuery(GET_ADVISOR_FIRM_DROPDOWN, {
    skip: !canChangeFirm,
  });
  const [updateSupportAdminFirm, { client }] = useMutation(
    UPDATE_SUPPORT_ADMIN_FIRM
  );
  const { menu } = props;
  const classes = useStyles();

  const dropdownOptions =
    data &&
    data.allAdvisorFirms
      .filter((firm: IAdvisorFirm) => firm.id !== userData.user.advisorFirmId)
      .sort((a: IAdvisorFirm, b: IAdvisorFirm) => {
        if (a.companyName < b.companyName) return -1;
        if (a.companyName > b.companyName) return 1;
        return 0;
      });

  const handleChange = async (id: string) => {
    showMessage("Redirecting to your firm");
    await updateSupportAdminFirm({
      variables: {
        whereAdvisorFirm: {
          id,
        },
      },
    });
    await changeHostname(userData.subDomainName);
    redirectHome(id); //
    handleClose();
    await client.resetStore();
    changePathnameWithoutRedirect(id);
  };

  const redirectHome = (advisorFirmId: string) => {
    const path = getPath(RouteName.Home, { advisorFirmId });
    history.push(path);
  };

  // Pathname advisor firm id
  const changePathnameWithoutRedirect = (advisorFirmId: string) => {
    const path = window.location.pathname.split("/");
    const index = path.findIndex((item) => {
      if (item === "") return false;
      if (isNaN(Number(item))) return false;
      return typeof Number(item) === "number";
    });
    path.splice(index, 1, advisorFirmId);
    const pathString = path.join("/");
    window.history.pushState("", "", pathString);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.drawerParent}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <LogoDropDown
            imageUrl={userData.logo}
            companyName={userData.companyName}
            canChangeFirm={canChangeFirm}
            dropdownOptions={dropdownOptions}
            handleChange={handleChange}
            handleClick={handleClick}
            handleClose={handleClose}
            open={open}
            anchorEl={anchorEl}
          />
          <hr className={classes.divider} />
          {menu}
        </div>
      </Drawer>
    </div>
  );
};

export default SideNav;
